const form_data_set = {
  apply_form_data_form: {
    applyAmount: "50000",
    applyTerm: 24,
    loanUse: "001",
    financingIndustry: "E",
    name: "赖玉旺",
    certificateNo: "362103198308130210",
    bankNo: "6217000010062712594",
    openBank: "中国建设银行",
    bankPhone: "13312341234",
    smsCode: "1021",
    vehiclePlateNo: "渝A5LW73",
    vehicleVin: "LEFCLCCB3LT090087",
    registerDate: "2020-10-10",
    workDistance: "20000"
  },
  apply_form_data_area: {
    longitude: 114.16936,
    latitude: 22.3193,
    cityCode: "810100",
    cityName: "香港岛",
    provinceName: "香港特别行政区",
    provinceCode: "810000"
  },
  apply_form_data_personal_form: {
    educationType: "1",
    academicDegreeType: "3",
    wechatNo: "yoxi",
    email: "123@qq.com",
    firstLoanType: "Y",
    maritalStatus: "20",
    nation: "汉",
    address: "湖南省湘潭市西湖区石马头2号附1号",
    spouseName: "赖玉旺",
    spouseCertNo: "362103198308130220",
    spousePhone: "13312344321",
    workUnit: "俄罗斯的",
    addrType: "1",
    localResideYears: "1",
    provinceCode: "150000",
    cityCode: "150100",
    regionCode: "150104",
    provinceName: "内蒙古自治区",
    cityName: "呼和浩特市",
    regionName: "玉泉区",
    resideAddress: "详细地址"
  },
  apply_form_data_personal_con1_form: {
    contactName: "张三",
    contactPhone: "13312312312",
    contactRelation: "2"
  },
  apply_form_data_personal_con2_form: {
    contactName: "李四",
    contactPhone: "13332132132",
    contactRelation: "2"
  },
  apply_form_data_works_form: {
    workUnit: "公司",
    liceId: "9527",
    brtype: "10",
    industryCode: "A",
    staffSize: "1",
    techTitle: "1",
    duty: "002",
    workNature: "10",
    entryTime: "2015-01-01",
    workYears: "1",
    provinceCode: "150000",
    cityCode: "150300",
    regionCode: "150304",
    provinceName: "内蒙古自治区",
    cityName: "乌海市",
    regionName: "乌达区",
    unitAddress: "地址",
    annualIncome: "5",
    annualHouseholdIncome: "50",
    selfEmployed: "Y"
  },
  apply_form_data_car_form: {
    bizNo: "DY1028716901582352384",
    vehiclePlateNo: "渝A5LW73 ",
    vin: "LEFCLCCB3LT090087",
    carColor: "red",
    licenseProvinceCode: "120000",
    licenseCityCode: "120100",
    licenseProvinceName: "天津市",
    licenseCityName: "天津市",
    licenseCode: ["120000", "120100"],
    purchaseAmount: "10000",
    acceptLocation: "受理地",
    transferNumber: "1",
    loadLimit: "无",
    releasePledge: "Y"
  }
};

export const setFormData = (_this, objkey, key) => {
  _this.$set(_this, objkey, form_data_set[key]);
};
