<template>
  <div class="home-wrap">
    <div class="content-main">
      <div class="product-name">{{ productName }}</div>
      <div class="quota-block block">
        <p>最高额度（元）</p>
        <div class="highest-amt-num">{{ singleCreditLimit | amount }}</div>
        <div class="amt-input">
          <van-field
            v-model="form.applyAmount"
            type="number"
            placeholder="请输入金额（元）"
          />
        </div>
        <div class="content">
          <Form
            ref="formObj"
            :defaultForm="form"
            :defaultText.sync="text"
            :fields="fields"
          >
            <van-field
              slot="field-custom-name"
              v-model.trim="form.name"
              label="姓名"
              placeholder="上传识别"
              :rules="required"
              required
              disabled
              input-align="right"
            >
              <template #right-icon>
                <ImgIcon :size="28" color="#4191f9" @click="openIdcarOcr" />
              </template>
            </van-field>
            <div class="input-wrap verify-code" slot="field-custom-smsCode">
              <van-field
                label="验证码"
                v-model="form.smsCode"
                placeholder="请输入验证码"
                required
                type="digit"
                :rules="required"
              />

              <div
                :class="['send-btn', sending ? 'sending' : '']"
                @click="getCode"
              >
                {{ sending ? `${second}s` : "获取验证码" }}
              </div>
            </div>
            <van-field
              slot="field-custom-vehiclePlateNo"
              v-model.trim="form.vehiclePlateNo"
              label="车牌"
              placeholder="上传识别"
              :rules="required"
              required
              disabled
              input-align="right"
            >
              <template #right-icon>
                <ImgIcon :size="28" color="#4191f9" @click="licenseOcr" />
              </template>
            </van-field>
            <van-field
              slot="field-custom-guarantee"
              v-model.trim="form.guarantee"
              label="担保人"
              placeholder="(选填，可申请更高额度)"
              disabled
              label-width="3em"
              input-align="left"
            >
              <template #button>
                <div class="field-btn" @click="toGuarantor">添加</div>
              </template>
            </van-field>
          </Form>
        </div>
      </div>
    </div>

    <div class="btns">
      <van-button type="info" size="small" @click="saveFn">提交</van-button>
    </div>
    <IdentityOCR
      v-model="ocrShow"
      :prePlatOrderNo="prePlatOrderNo"
      @success="ocrSuccess"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { areaList } from "@vant/area-data";
import { getDetailLocation } from "@/api/location.js";
import { uploadFile } from "@/api/upload";
import { ocrLicense, applyCreate, authSmsCode } from "@/api/apply";
import { getSomeEnums } from "@/api/enums";
import { setFormData } from "./dev_form_json";
import { Notify } from "vant";
export default {
  components: {
    Form: () => import("@/components/Form/Index"),
    ImgIcon: () => import("@/components/ImgIcon.vue"),
    IdentityOCR: () => import("./components/IdentityOCR.vue")
  },
  data() {
    return {
      prePlatOrderNo: "",
      required: [
        {
          required: true
        }
      ],
      singleCreditLimit: 0, // 最高金额
      productName: "产品名称",
      productNo: "",
      form: {},
      text: {},
      areaInfo: {},

      ocrShow: false,

      pageLoading: false,
      supportPeriod: [],
      loanUseEnums: [],
      industryEnums: [],

      sending: false, // loading
      second: 60,
      itv: null // 定时器
    };
  },
  computed: {
    fields: {
      get() {
        return [
          {
            type: "select",
            label: "申请期数",
            required: true,
            key: "applyTerm",
            enums: this.supportPeriod
          },
          {
            type: "select",
            label: "贷款用途",
            required: true,
            key: "loanUse",
            enums: this.loanUseEnums
          },
          {
            type: "select",
            label: "融资行业投向",
            required: true,
            key: "financingIndustry",
            enums: this.industryEnums
          },
          {
            type: "custom",
            label: "姓名",
            placeholder: "上传识别",
            required: true,
            key: "name",
            validator: (v) => {
              const reg = /^(?:[\u4e00-\u9fa5·]{2,20})$/;
              if (v && !reg.test(v)) throw "请输入正确的姓名";
              else return true;
            }
          },
          {
            type: "text",
            label: "身份证号",
            required: true,
            placeholder: "上传识别",
            disabled: true,
            key: "certificateNo",
            validator: (v) => {
              const reg =
                /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
              if (v && !reg.test(v)) throw "请输入正确的身份证号";
              else return true;
            }
          },
          {
            type: "text",
            label: "银行卡",
            required: true,
            key: "bankNo",
            validator: (v) => {
              const reg = /^[1-9]\d{9,29}$/;
              if (v && !reg.test(v)) throw "请输入正确的银行卡号";
              else return true;
            }
          },
          {
            type: "text",
            label: "开户银行",
            required: true,
            key: "openBank"
          },
          {
            type: "number",
            label: "银行预留手机号",
            required: true,
            labelWidth: "7.2em",
            key: "bankPhone",
            validator: (v) => {
              const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
              if (v && !reg.test(v)) throw "请输入正确的手机号";
              else return true;
            }
          },
          {
            type: "custom",
            label: "验证码",
            placeholder: "请输入验证码",
            required: true,
            key: "smsCode"
          },
          {
            type: "custom",
            label: "车牌号",
            required: true,
            key: "vehiclePlateNo",
            validator: (v) => {
              const reg =
                /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
              if (v && !reg.test(v)) throw "请输入正确的车牌号";
              else return true;
            }
          },
          {
            type: "text",
            label: "车架号",
            required: true,
            // disabled: true,
            key: "vehicleVin",
            placeholder: "上传识别",
            validator: (v) => {
              const reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
              if (v && !reg.test(v)) throw "请输入正确的车架号";
              else return true;
            }
          },
          {
            type: "text",
            label: "车辆登记日期",
            required: true,
            disabled: true,
            key: "registerDate",
            placeholder: "上传识别"
          },
          {
            type: "number",
            label: "行驶里程(公里)",
            required: true,
            labelWidth: "7.2em",
            key: "workDistance",
            maxlength: 7,
            validator: (v) => {
              const reg = /^(?:0|(?:-?[1-9]\d*))$/;
              if (!v) throw `请输入行驶里程`;
              else if (v && +v < 0) throw `行驶里程大于等于零的数字`;
              else if (v && !reg.test(v)) throw `行驶里程请输入整数`;
              else return true;
            }
          },
          {
            type: "custom",
            label: "担保人",
            key: "guarantee"
          }
        ];
      },
      set() {
        this.$forceUpdate();
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler(v) {}
    }
  },
  created() {
    this.initEnums();
    this.initProductInfo();
    this.init();
    this.ipLocation();
    window.agilityAutoFill = this.agilityAutoFill;
  },
  methods: {
    ...mapGetters(["getProductInfo", "getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.prePlatOrderNo = data?.prePlatOrderNo || "";
    },
    // init enums
    async initEnums() {
      try {
        const [d1, d2] = await Promise.all([
          getSomeEnums("loanPurpose"),
          getSomeEnums("invest")
        ]);
        this.loanUseEnums = d1;
        this.industryEnums = d2;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 产品信息
    initProductInfo() {
      try {
        const data = this.getProductInfo();
        const {
          repaymentMethodStr,
          configProductTermResDTOList,
          repaymentMethod,
          singleCreditLimit,
          productName,
          productNo
        } = data || {};
        this.singleCreditLimit = singleCreditLimit || 0;
        this.productName = productName || "产品名称";
        this.productNo = productNo || "";

        const enums = (configProductTermResDTOList || []).map((e) => ({
          text: `${e.term} 期`,
          value: e.term
        }));
        this.supportPeriod = enums || [];
        if (enums.length && enums.length == 1) {
          this.$set(this.form, "supportPeriod", enums[0].value);
        }
        this.text.repaymentMethodStr = repaymentMethodStr;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    // 金额校验
    applyAmountCheck(val) {
      const reg = /^\+?[1-9]\d*$/;
      if (!reg.test(val) || +val > +this.singleCreditLimit) {
        return Promise.reject("请输入正整数，且小于等于最高额度");
      } else {
        return Promise.resolve(true);
      }
    },
    toGuarantor() {
      const redirect = encodeURIComponent(this.$route.fullPath);
      this.$router.push({
        path: "/guarantor",
        query: {
          redirect
        }
      });
    },
    async saveFn() {
      if (this.pageLoading) return;
      Notify.clear();
      try {
        await this.applyAmountCheck(this.form.applyAmount);
        await this.$refs.formObj.validate();

        const pro = this.getProductInfo();
        const { productNo, merchantNo, agentNo } = pro;
        const params = {
          prePlatOrderNo: this.prePlatOrderNo,
          productNo,
          merchantNo,
          agentNo,
          ...this.form,
          ...this.areaInfo
        };
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        this.pageLoading = true;
        const { data } = await applyCreate(params);
        const { description, status } = data;
        if (status == "SUCCESS") {
          this.$toast.success("申请成功");
          this.$router.push("/identity-portrait-face");
        } else {
          Notify({
            type: "danger",
            message: description,
            duration: 0
          });
        }
      } catch (error) {
        this.$toast.clear();
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    // 根据 城市信息反查 区域code
    async ipLocation() {
      try {
        const that = this;
        /* eslint-disable-next-line */
        var lo = new T.Geolocation();

        const fn = function (e) {
          const lat = e?.lnglat?.lat;
          const lng = e?.lnglat?.lng;
          if (lat && lng) {
            that.getDetailLocation(lng, lat);
          } else {
            console.log("获取定位失败", e);
          }
        };
        lo.getCurrentPosition(fn);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getDetailLocation(lng, lat) {
      try {
        const resp = await getDetailLocation(lng, lat);
        let data = resp?.data?.result?.addressComponent ?? {};
        // let data = testData?.result?.addressComponent ?? {};

        let cityCode =
          data.city_code && typeof data.city_code == "string"
            ? data.city_code.slice(3)
            : "";
        let provinceCode =
          data.province_code && typeof data.province_code == "string"
            ? data.province_code.slice(3)
            : "";

        if (!cityCode)
          cityCode =
            data.county_code && typeof data.county_code == "string"
              ? data.county_code.slice(3).slice(0, 4) + "00"
              : "";

        const [proName, cityName] = this.getAraeNameByCode(
          provinceCode,
          cityCode
        );
        this.areaInfo = {
          longitude: lng,
          latitude: lat,
          cityCode: cityCode,
          cityName: cityName,
          provinceName: proName,
          provinceCode: provinceCode
        };
        // this.text.area = `${proName}/${cityName}`;
      } catch (e) {
        this.$errMsg(e);
      }
    },
    // 通过城市code获取城市name
    getAraeNameByCode(p, c) {
      const { province_list, city_list } = areaList;

      let c1 = province_list[p];
      let c2 = city_list[c];

      return [c1, c2];
    },
    ocrSuccess({ name, idCardNo }) {
      this.$set(this.form, "name", name);
      this.$set(this.form, "certificateNo", idCardNo);
    },
    openIdcarOcr() {
      try {
        const prePlatOrderNo = this.prePlatOrderNo;
        if (!prePlatOrderNo) throw "预进件订单错误";
        this.ocrShow = true;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getCode() {
      if (this.sending) return;
      const mobilePass = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(
        this.form.bankPhone
      );
      if (!mobilePass) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      try {
        this.$toast.loading({
          message: "处理中...",
          forbidClick: true
        });
        await authSmsCode(this.form.bankPhone);

        setTimeout(() => {
          this.$toast.success("验证码已发送，请注意查收！");
        }, 100);
        this.sending = true;
        this.itv = setInterval(() => {
          this.second--;
          if (this.second <= 0) {
            this.second = 60;
            this.sending = false;
            clearInterval(this.itv);
          }
        }, 1000);
        return Promise.resolve(true);
      } catch (error) {
        this.sending = false;

        this.$errMsg(error);
        return Promise.reject(error);
      }
    },
    async uploadUseInput() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.click();

      return new Promise((resp) => {
        input.onchange = async () => {
          const file = input.files[0];
          const { ossAccessUrl } = await uploadFile(file);
          return resp(ossAccessUrl);
        };
      });
    },
    async licenseOcr() {
      try {
        const prePlatOrderNo = this.prePlatOrderNo;
        if (!prePlatOrderNo) throw "预进件订单错误";
        const ossAccessUrl = await this.uploadUseInput();
        const { data } = await ocrLicense({
          prePlatOrderNo,
          imageUrl: ossAccessUrl,
          side: "face"
        });
        let form = {
          ...this.form
        };
        form.vehiclePlateNo = data.carPlateNum || "";
        form.vehicleVin = data.vin || "";
        form.registerDate = data.registerDate || "";
        this.$set(this, "form", form);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 开发快速调试
    agilityAutoFill() {
      let isdev = process.env.NODE_ENV === "development";
      if (isdev) {
        setFormData(this, "form", "apply_form_data_form");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./apply-index.scss";
</style>
