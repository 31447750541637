import { get, post, del } from "@/utils/https";

// 贷款用途
export function loanUseEnums() {
  return get(`/api/v1/common/cus/infra/dic/loan-purpose`);
}
// 行业
export function industryEnums() {
  return get(`/api/v1/common/cus/infra/dic/industry`);
}
// 行驶证识别（预进件）
export function ocrLicense(data) {
  if (!data.prePlatOrderNo) throw "预进件订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/ocr-driving-licence/${data.prePlatOrderNo}`,
    data
  );
}
// 申请订单创建
export function applyCreate(data) {
  return post(`/api/v1/common/cus/apply/wjf/wjf/apply`, data);
}
// 银行预留手机号验证码
export function authSmsCode(mobile) {
  return get(`/api/v1/common/cus/apply/wjf/wjf/apply/${mobile}`);
}

// 人脸对比
export function faceCompare({ prePlatOrderNo, url }) {
  if (!prePlatOrderNo) throw "预进件订单号错误";
  return post(`/api/v1/common/cus/apply/wjf/face/idcard/${prePlatOrderNo}`, {
    url
  });
}
// ocr识别
export function ocrIdentify(data) {
  return post(`/api/v1/common/cus/apply/wjf/ocr/idcard`, data);
}
// 识别提交认证
export function ocrIdentifySubmit(data) {
  if (!data.prePlatOrderNo) throw "订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/ocr/idcard/${data.prePlatOrderNo}`,
    data
  );
}

// 担保人添加
export function guarantorAdd(data) {
  if (!data.prePlatOrderNo) throw "订单号错误";
  return post(
    `/api/v1/common/cus/apply/wjf/pre/guarantor/${data.prePlatOrderNo}`,
    data
  );
}

// 订单详情
export function orderDetailInfo(bizNo) {
  if (!bizNo) throw "订单号错误";
  return get(`/api/v1/common/cus/apply/wjf/${bizNo}`);
}

// 创建个人信息
export function createPersonalInfo(data) {
  return post(`/api/v1/common/cus/apply/wjf/customer/${data.bizNo}`, data);
}

// 创建工作信息
export function createWorkInfo(data) {
  return post(`/api/v1/common/cus/apply/wjf/customer/work/${data.bizNo}`, data);
}

// 发起评估
export function assessVehicle(bizNo) {
  return post(`/api/v1/common/cus/apply/wjf/evaluation/${bizNo}`);
}

// 创建车辆信息
export function createVehicleInfo(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/customer/vehicle/${data.bizNo}`,
    data
  );
}
// 进件材料配置
export function materialsConfigList(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/${bizNo}/material/list`);
}
export function saveMaterialsImg(data) {
  return post(`/api/v1/common/cus/apply/wjf/${data.bizNo}/material`, data);
}
export function delMaterialsImg(id) {
  return del(`/api/v1/common/cus/apply/wjf/${id}/material/image`);
}

// 绑卡状态
export function bindCardStatus(bizNo) {
  return get(`/api/v1/common/cus/apply/wjf/protocol/bind/card/status/${bizNo}`);
}
// 发送验证码
export function sendBindCardSms(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/send/protocol/sms/${data.bizNo}`,
    data
  );
}
// 提交信息
export function bindCardSubmit(data) {
  return post(
    `/api/v1/common/cus/apply/wjf/protocol/bind/card/${data.bizNo}`,
    data
  );
}
